.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 41px;
    line-height: 50px;
    /* identical to box height */

    letter-spacing: -0.41px;

    margin: 16px 0px;

    /* Lila */
    color: #412563;
}

.subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;

    margin: 16px 0px;

    /* Lila */
    color: #412563;
}

.payButton {
    width: 324px;
    height: 54px;

    /* Lila */
    background: #412563;
    border-radius: 8px;


    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    text-align: center;
    letter-spacing: -0.41px;

    /* White */

    color: #FFFFFF;

    margin-top: 40px;
}

.payButtonDisabled {
    background: gray;
}

.authPopup {
    position: fixed;
    top: 60%;
    left: 60%;
    transform: translate(-60%, -60%);

    border: 1px solid #ccc;
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 20px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.authPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.authPopupTitle {
    /* Page/Subtitle */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.41px;

    margin-bottom: 30px;

    color: #000000;
}

.authPopupButton {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;

    padding: 20px 40px;
    color: #2F80ED;

    background-color: white;
    border-width: 0;
}

.authPopupStatus {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;

    padding: 20px 40px;
    /*color: #2F80ED;*/
}