@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap);
.App {
}

.content {
  padding: 0px 136px;
  margin-top: 72px;
}

.Header_header__6J3mP {
    height: 96px;
    padding: 0px 136px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;

    /* Rosa */
    background: #F9DFD8;
}

.Header_logo__1_msB {
    height: 60px;
    /*justify-self: flex-start;*/
}

.Header_companyName__3w__W {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;
    /*justify-self: flex-end;*/

    color: #412563;
}

.LoginPage_container__19pgm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;;
}

.LoginPage_form__GmzRf {
  max-width: 400px;
  padding: 0px 36px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.LoginPage_form__GmzRf input {
  height: 24px;
}

.LoginPage_form__GmzRf * + * {
  margin-top: 12px;
}

.LoginPage_form__GmzRf button {
  height: 40px;
  margin-top: 12px;
}

.LoginPage_logo__1xT6G {
  height: 120px;
}

.InvoicesList_container__3EVaa {
    margin: 0px -44px;
}

.InvoicesList_table__2OtRW {
    border-collapse: separate;
    border-spacing: 40px 22px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: -0.41px;

    /* Lila */

    color: #412563;
}

.InvoicesList_th__L7leI {
    font-family: Montserrat;
    font-weight: bold;
    /* identical to box height */
    text-align: start;
}

.InvoicesList_right__3Q9Yw {
    text-align:right;
}

.InvoiceStatusLabel_invoiceLabelStatus__1yOKt {
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    display: inline-block;

    color: white;
    background-color: #412462;
}

.InvoiceStatusLabel_unbooked__28IQs {

}

.InvoiceStatusLabel_readyForPayment__2XLaV {

}

.InvoiceStatusLabel_paymentPending__1ck5m {
    background-color: #47b6a4;
}

.InvoiceStatusLabel_paid__3GHbB {
    background-color: #b0c4e3;
    color: #5e4f84;
}

.InvoiceStatusLabel_cancelled__2ODMp {

}
.InvoicesPayment_title__K8c82 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 41px;
    line-height: 50px;
    /* identical to box height */

    letter-spacing: -0.41px;

    margin: 16px 0px;

    /* Lila */
    color: #412563;
}

.InvoicesPayment_subtitle__1LJS- {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;

    margin: 16px 0px;

    /* Lila */
    color: #412563;
}

.InvoicesPayment_payButton__2WObX {
    width: 324px;
    height: 54px;

    /* Lila */
    background: #412563;
    border-radius: 8px;


    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    text-align: center;
    letter-spacing: -0.41px;

    /* White */

    color: #FFFFFF;

    margin-top: 40px;
}

.InvoicesPayment_payButtonDisabled__jfidw {
    background: gray;
}

.InvoicesPayment_authPopup__2tM4Y {
    position: fixed;
    top: 60%;
    left: 60%;
    -webkit-transform: translate(-60%, -60%);
            transform: translate(-60%, -60%);

    border: 1px solid #ccc;
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 20px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.InvoicesPayment_authPopupOverlay__hSZq6 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.InvoicesPayment_authPopupTitle__1pwpk {
    /* Page/Subtitle */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.41px;

    margin-bottom: 30px;

    color: #000000;
}

.InvoicesPayment_authPopupButton__2F2pW {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;

    padding: 20px 40px;
    color: #2F80ED;

    background-color: white;
    border-width: 0;
}

.InvoicesPayment_authPopupStatus__2q8AA {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;

    padding: 20px 40px;
    /*color: #2F80ED;*/
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

