.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;;
}

.form {
  max-width: 400px;
  padding: 0px 36px;

  display: flex;
  flex-direction: column;
}

.form input {
  height: 24px;
}

.form * + * {
  margin-top: 12px;
}

.form button {
  height: 40px;
  margin-top: 12px;
}

.logo {
  height: 120px;
}
