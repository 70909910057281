.header {
    height: 96px;
    padding: 0px 136px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /* Rosa */
    background: #F9DFD8;
}

.logo {
    height: 60px;
    /*justify-self: flex-start;*/
}

.companyName {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.41px;
    /*justify-self: flex-end;*/

    color: #412563;
}
