.invoiceLabelStatus {
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    display: inline-block;

    color: white;
    background-color: #412462;
}

.unbooked {

}

.readyForPayment {

}

.paymentPending {
    background-color: #47b6a4;
}

.paid {
    background-color: #b0c4e3;
    color: #5e4f84;
}

.cancelled {

}