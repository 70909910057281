.container {
    margin: 0px -44px;
}

.table {
    border-collapse: separate;
    border-spacing: 40px 22px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: -0.41px;

    /* Lila */

    color: #412563;
}

.th {
    font-family: Montserrat;
    font-weight: bold;
    /* identical to box height */
    text-align: start;
}

.right {
    text-align:right;
}
